import { bypassCORS } from "../global";

// XXX_INTERNAL - allows to connect directly to another docker container. Will not work with Client Side Render !!!
// XXX_EXTERNAL - allows to connect to endpoint via domain. Will work both with SSR (avoid using) and CSR.
// XXX_NODE     - connects to node endpoint and exists only for frontend purposes.

export const API_USER_INTERNAL = process.env.NEXT_PUBLIC_API_USER_INTERNAL;
export const API_USER_EXTERNAL = process.env.NEXT_PUBLIC_API_USER_EXTERNAL;
export const API_USER_NODE = '/api/user';
//------------------
//           Info
//------------------
export const API_USER_DATA_EXTERNAL = bypassCORS(`${API_USER_EXTERNAL}/oauth_server/api/me`);
//        --
export const API_EMAIL_SUBSCRIPTIONS_EXTERNAL = bypassCORS(`${API_USER_EXTERNAL}/subscriptions`);
//        --
export const API_PLANS_INTERNAL = `${API_USER_INTERNAL}/plans/info/`;
//------------------
//           Auth
//------------------
export const API_AUTH_INTERNAL = `${API_USER_INTERNAL}/auth`;
export const API_AUTH_EXTERNAL = `${API_USER_EXTERNAL}/auth`;
//        --
export const API_LOGIN_INTERNAL = `${API_AUTH_INTERNAL}/login`;
export const API_LOGIN_NODE = `${API_USER_NODE}/login`;
//        --
export const API_SIGN_UP_EXTERNAL = `${API_AUTH_EXTERNAL}/register`;
//        --
export const API_LOGOUT_EXTERNAL = bypassCORS(`${API_AUTH_EXTERNAL}/logout`);
//------------------
//           Auth social
//------------------
export const API_LOGIN_SOCIAL_EXTERNAL = bypassCORS(`${API_USER_EXTERNAL}/oauth_client/login/`);

export const API_LOGOUT_SOCIAL_EXTERNAL = `${API_USER_EXTERNAL}/oauth_client/deauthorized/`;
//------------------
//           Update
//------------------
export const API_EMAIL_CONFIRMATION_RESEND_INTERNAL = `${API_AUTH_INTERNAL}/confirmation/resend`;
export const API_EMAIL_CONFIRMATION_RESEND_NODE = `${API_USER_NODE}/email-confirmation-resend`;
//        --
export const API_EMAIL_CONFIRMATION_CHANGE_INTERNAL = `${API_AUTH_INTERNAL}/change_email`;
export const API_EMAIL_CONFIRMATION_CHANGE_NODE = `${API_USER_NODE}/email-confirmation-change`;
//        --
export const API_PASSWORD_RECOVER_INTERNAL = `${API_USER_INTERNAL}/auth/recover`;
export const API_PASSWORD_RECOVER_NODE = `${API_USER_NODE}/password-recover`;
//        --
export const API_PASSWORD_RESET_INTERNAL = `${API_USER_INTERNAL}/auth/reset`;
export const API_PASSWORD_RESET_NODE = `${API_USER_NODE}/password-reset`;
//        --
export const API_EMAIL_CONFIRM_INTERNAL = `${API_USER_INTERNAL}/auth/email/confirm`;
//        --
export const API_ACCOUNT_COMPLETE_EXTERNAL = bypassCORS(`${API_USER_EXTERNAL}/auth/account/complete`);
//        --
export const API_PASSWORD_SET_EXTERNAL = bypassCORS(`${API_USER_EXTERNAL}/auth/password`);
//------------------
//           Deactivation
//------------------
export const API_USER_SUSPEND_INTERNAL = `${API_USER_INTERNAL}/auth/account/delete`;
export const API_USER_SUSPEND_NODE = `${API_USER_NODE}/suspend`;
//        --
export const API_ACCOUNT_RESTORE_INTERNAL = `${API_USER_INTERNAL}/auth/account/cancel-delete`;
export const API_ACCOUNT_RESTORE_NODE = `${API_USER_NODE}/account-restore`;
//------------------
//           Deployments
//------------------
export const API_INSTALL_INTERNAL = `${API_USER_INTERNAL}/install/`;
export const API_INSTALL_EXTERNAL = `${API_USER_EXTERNAL}/install/`;
export const API_INSTALL_NODE = `${API_USER_NODE}/deployments`;
//        --
export const API_USER_IP_GET_EXTERNAL = bypassCORS(`${API_USER_EXTERNAL}/auth/ip`);
//        --
export const API_OWN_CONNECT_STATUS_EXTERNAL = bypassCORS(`${API_USER_EXTERNAL}/install/private/connect`);
//        --
export const API_OWN_CMD_EXTERNAL = bypassCORS(`${API_USER_EXTERNAL}/install/private/cmd`);
//        --
export const API_DEPLOYMENT_DELETE_EXTERNAL = bypassCORS(API_INSTALL_EXTERNAL);
//        --
export const API_DEPLOYMENT_CREATE_EXTERNAL = bypassCORS(API_INSTALL_EXTERNAL);
//        --
export const API_DEPLOYMENT_VALIDATE_EXTERNAL = bypassCORS(`${API_INSTALL_EXTERNAL}pre-check`);
//        --
export const API_DEPLOYMENT_MIGRATE_EXTERNAL = `${API_INSTALL_EXTERNAL}/migrate/`;
//------------------
//           Rating
//------------------
export const API_STACKS_RATING_GET_INTERNAL = `${API_USER_INTERNAL}/stacks_rating/`;
//        --
export const API_STACKS_RATING_ADD_EXTERNAL =  `${API_USER_EXTERNAL}/stacks_rating/add`;