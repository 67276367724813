// function for common use to get required plan data
import { store } from "../../../redux/store";
import * as config from "../../../settings/global";

export function getCurrentPlan(plans, user) {

    let currentPlan = {
        code: plans[0]['code'],
        name: plans[0]['name'],
        period: null,
        supportedFeatures: plans[0]['supportedFeatures']
    };

    if (!!user && !!Object.keys(user).length && !!user.plan.period) {

        let {period, name, code} = user.plan;
        let planData = plans.find(plan => plan.code[period] === code);

        currentPlan = {
            code: code,
            name: name,
            period: period,
            supportedFeatures: planData['supportedFeatures']
        };
    }

    return currentPlan
}

export function getPlanIndexByRequirements(requiredID, plans) {

    requiredID = parseFloat(requiredID);

    let foundPeriod = {
        requiredPeriod: ''
    };

    return plans.findIndex(plan => {
        let {stackRequirements} = plan;
        return Object.keys(stackRequirements).some(period => {
            foundPeriod.requiredPeriod = Object.keys(stackRequirements).find(_period => stackRequirements[_period] === requiredID);
            return stackRequirements[period] === requiredID
        })
    })
}

export function getPlanByRequirements(requiredID, plans) {

    requiredID = parseFloat(requiredID);

    let foundPeriod = {
        requiredPeriod: ''
    };

    let foundPlan = plans.find(plan => {
        let {stackRequirements} = plan;
        return Object.keys(stackRequirements).some(period => {
            foundPeriod.requiredPeriod = Object.keys(stackRequirements).find(_period => stackRequirements[_period] === requiredID);
            return stackRequirements[period] === requiredID
        })
    })

    return {...foundPlan, ...foundPeriod, ...{isFree: requiredID === 1}}
}

export function getPlanByCode(code, plans) {
    let foundPlan = plans.find(plan => {
        return (
            plan.code === code
            || plan.code['month'] === code
            || plan.code['year'] === code
        )
    });

    return !!foundPlan && !!Object.keys(foundPlan) ? foundPlan : null
}

export function isStackSupportedByPlan(requiredPlanId, plan) {
    requiredPlanId = parseFloat(requiredPlanId);
    switch (true) {
        case !!Object.keys(plan).length && !!plan.stackRequirements:
            return plan.stackRequirements['monthly'] >= requiredPlanId || plan.stackRequirements['annually'] >= requiredPlanId
        default:
            if (config.DEBUG) console.error('Exception: Invalid data passed to "isStackSupportedByPlan" function.')
            return false
    }
}

// function for common use to define if stack is supported by current plan
export function isStackSupportedByUser(requiredPlanId) {

    let {user} = store.getState().userData;
    requiredPlanId = parseFloat(requiredPlanId);

    switch (true) {
        case !user && requiredPlanId === 1: // case user didn't signed in and stack plan is free'
            return true;
        case !user && requiredPlanId !== 1: // case user didn't signed in and stack plan is not free
            return false;
        case !!Object.keys(user).length && !user.plan.supportedStacks: // case all data hasn't been fetched
            return false
        case !!Object.keys(user).length && !!user.plan.supportedStacks:
            return user.plan.supportedStacks['monthly'] >= requiredPlanId || user.plan.supportedStacks['annually'] >= requiredPlanId
        default:
            if (config.DEBUG) console.error('Exception: Invalid data passed to "isStackSupportedByUser" function.')
            return false
    }
}

// this is not for stack requirements, only for features (old implementation)
export function defineAvailabilityAndPermissions(plans, user) {

    let updatedData = [];
    let indexOfCurrentPlan = 0; // free plan by default if user is not authenticated

    if (!!Object.keys(user || {}).length) {
        indexOfCurrentPlan = plans.findIndex(plan => plan.name === user.plan.name)
    }

    plans.map((plan, planIndex) => {

        let supportedFeaturesData = ['integrated_features', 'extended_features'];
        let supportedFeatures = [];
        // add features supported by plan type
        plan['includes'].map(item => {
            let {code, available} = item;
            supportedFeaturesData.map(type => {
                if (code === type && !!available) supportedFeatures.push(type);
            })
        });
        // add features supported by individual plan
        if (planIndex === plans.length - 1 && !supportedFeatures.includes('subscriptions')) {
            supportedFeatures.push('subscriptions')
        }

        updatedData.push({
            ...plan,
            available: planIndex >= indexOfCurrentPlan,
            supportedFeatures: supportedFeatures
        })
    });

    return updatedData
}